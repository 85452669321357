<template>
  <div>
    <v-row no-gutters class="px-5">
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.job_id" disabled label="Job Id"></mf-text-input>
            </div>
          </template>
          <span>
            Id do job gerado pelo starlord
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.dest_dir" :rules="[notEmptyRule]" label="Dest dir"></mf-text-input>
            </div>
          </template>
          <span>
            Pasta de destino
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.provider" :rules="[notEmptyRule]" label="Provider"></mf-text-input>
            </div>
          </template>
          <span>
            Nome do provedor de conexão.
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.source" :rules="[notEmptyRule]" label="Source"></mf-text-input>
            </div>
          </template>
          <span>
            Origem da conexão
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.prefix" :rules="[notEmptyRule]" label="Prefix"></mf-text-input>
            </div>
          </template>
          <span>
            Prefixo no bucket na cloud onde o arquivo de resultado será enviado <br />
            Prefixo Completo:<b>{{ integration.config.prefix }}</b>
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.bucket" :rules="[notEmptyRule]" label="Bucket"></mf-text-input>
            </div>
          </template>
          <span>
            Nome do bucket na cloud para onde o arquivo com o resultado da query será enviado
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row no-gutters>
                <v-col>
                  <mf-text-input v-model="integration.cron_schedule" :rules="[notEmptyRule]" disabled label="Cron"></mf-text-input>
                </v-col>
                <v-col class="mt-3" cols="auto">
                  <v-icon color="primary" @click="dialog_cron = true">mdi-plus</v-icon>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            Campo para configuração da cron
          </span>
        </v-tooltip>
      </v-col>
      <v-col v-if="edit" class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row no-gutters>
                <v-col>
                  <mf-toggle v-model="integration.active" label="Ativo" color="#334A58"></mf-toggle>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            Indica se a integração está ativa
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <dialog-cron v-model="dialog_cron" @confirmAction="SetCron"></dialog-cron>
  </div>
</template>

<script>
export default {
  components: {
    DialogCron: () => import('@/components/atomic-components/molecules/DialogCron.vue')
  },
  props: {
    integration: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: { mode: 'view', mainMenuBar: false },
      title: '',
      dialog_cron: false,
      notEmptyRule: v => !!v || 'Campo obrigatório',
      unit: ''
    }
  },
  methods: {
    generateGenericToken() {
      return Math.random()
        .toString(36)
        .slice(-6)
    },
    SetCron(cron) {
      this.integration.cron_schedule = cron
      this.dialog_cron = false
    }
  }
}
</script>
